import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { url } from "../Api";
import { PageContext } from "../Context/PageContextProvider";
import CreateLand from "../Components/CreateLand";
import EditLand from "../Components/EditLand";
import { AdminLandDetail } from "../Components/AdminHouseAndLandDetails";
import { useTable } from "react-table";
import { Tooltip } from "@mui/material";

const AdminListLand = () => {
  const [addModal, setAddModal] = useState(false);
  const [descModal, setDescModal] = useState(false);
  const { userInfo } = useContext(PageContext);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getId, setGetId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredLand, setFilteredLand] = useState([]);

  const getProperties = useCallback(() => {
    setLoading(true);
    const fn1 = async () => {
      let res = await axios.get(`${url}/properties`, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      });
      let data = res.data.properties;
      let land = data.filter((property) => {
        return property.type === "Land";
      });
      setProperties(land);
      setFilteredLand(land);
      setLoading(false);
    };
    fn1();
  }, [userInfo?.token]);

  useEffect(() => {
    getProperties();
  }, [getProperties]);

  const deleteProperty = (id) => {
    const fn1 = async () => {
      alert("Are you sure you want to delete property");
      let res = await axios.delete(`${url}/properties/${id}`, {
        headers: {
          Authorization: `bearer ${userInfo?.token}`,
        },
      });
      const newProperty = properties.filter((item) => item.id !== id);
      setProperties(newProperty);

      if (res) {
        window.location.reload();
      }
    };
    fn1();
  };

  const openDetails = (id) => {
    setGetId(id);
    setDescModal(true);
  };

  const openEdit = (id) => {
    setEditModal(true);
    setGetId(id);
  };

  useEffect(() => {
    const result = properties.filter((item) => {
      return item.name.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilteredLand(result);
  }, [search]);

  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "Location", accessor: "location" },
      {
        Header: "Developer",
        Cell: ({ row }) => <span>{row.original?.developer?.CompanyName}</span>,
      },
      {
        Header: "Price",
        Cell: ({ row }) => (
          <span>
            {row.original.price.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })}
          </span>
        ),
      },
      {
        Header: "Initial deposit",
        Cell: ({ row }) => (
          <span>
            {row.original.intialDeposit.toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
            })}
          </span>
        ),
      },
      {
        Header: "Plot Size",
        Cell: ({ row }) => <span>{`${row.original.details}Sqm`}</span>,
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <Tooltip
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "white",
                  },
                },
              }}
              title={
                <div className="bg-white divide-y divide-gray-100 rounded-lg shadow">
                  <ul className="py-2 text-sm text-gray-700">
                    <li>
                      <span
                        onClick={() => openEdit(row.original._id)}
                        className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        Edit
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => deleteProperty(row.original._id)}
                        className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        Delete
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => openDetails(row.original._id)}
                        className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        View details
                      </span>
                    </li>
                  </ul>
                </div>
              }
            >
              <i className="ri-more-2-fill text-xl cursor-pointer"></i>
            </Tooltip>
          </>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: filteredLand,
    });

  return (
    <div>
      <div className="md:-mt-14 flex justify-start md:justify-end gap-3">
        <div>
          <input
            placeholder="Search property.."
            className="border border-slate-500 py-2 pl-2 pr-5 font-medium rounded text-sm focus:outline-primary"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <button onClick={() => setAddModal(true)} className="button">
          Add +
        </button>
      </div>
      <CreateLand open={addModal} handleClose={() => setAddModal(false)} />
      <AdminLandDetail
        id={getId}
        open={descModal}
        handleClose={() => setDescModal(false)}
      />
      <EditLand
        id={getId}
        open={editModal}
        handleClose={() => setEditModal(false)}
      />
      {!loading && properties.length === 0 && (
        <div>
          <h5 className="pt-4 font-medium text-lg"> No Property yet</h5>
        </div>
      )}

      {loading && (
        <div>
          <h5 className="pt-4 font-medium text-lg">Loading....</h5>
        </div>
      )}
      {properties.length > 0 && (
        <table
          {...getTableProps()}
          className="w-full table-auto text-left mt-4"
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()} className="">
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps()}
                    className="text-xs py-2 text-accent"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="text-[#2E2E2E]">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        className="py-2 text-sm px-2"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        // <DataTable
        //   columns={columns}
        //   data={filteredLand}
        //   pagination
        //   fixedHeader
        //   responsive
        //   className="overflow-x-auto"
        //   striped
        //   highlightOnHover
        //   subHeader
        //   subHeaderComponent={
        //     <input
        //       placeholder="Search table.."
        //       className="border border-slate-500 py-2 pl-2 pr-5 font-medium rounded text-sm focus:outline-primary"
        //       value={search}
        //       onChange={(e) => setSearch(e.target.value)}
        //     />
        //   }
        //   subHeaderAlign="right"
        // />
      )}
    </div>
  );
};

export default AdminListLand;
