import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "../Api";

export const useFetchDevelopers = (userInfo) => {
  const [developers, setDevelopers] = useState([]);
  const [loading, setLoading] = useState(false);
  // === realtors ===//
  useEffect(() => {
    setLoading(true);
    axios({
      url: `${url}/developers`,
      method: "GET",
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    }).then((response) => {
      setLoading(false);
      let data = response.data.developers;
      setDevelopers(data);
    });
  }, [userInfo]);

  return { developers, loading };
};
