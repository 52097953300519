import React, { useContext, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import axios from "axios";
import { url } from "../../Api";
import { toast } from "react-toastify";
import { PageContext } from "../../Context/PageContextProvider";

export const EditDeveloper = ({ open, handleClose, id }) => {
  const { userInfo } = useContext(PageContext);
  const [CompanyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sending, setSending] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    axios({
      url: `${url}/developers/${id}`,
      method: "put",
      data: {
        'CompanyName': CompanyName,
        'email': email,
        'phone': phone,
      },
      headers: {
        authorization: `bearer ${userInfo?.token}`,
      },
    })
      .then((result) => {
        toast.success(result?.data?.message);
        setSending(false);
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setSending(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${url}/developers/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `bearer ${userInfo?.token}`,
        },
      })
      .then((response) => {
        let val = response.data.developer;
        setCompanyName(val.CompanyName);
        setEmail(val.email);
        setPhone(val.phone);
      })
      .catch((err) => {});
  }, [userInfo?.token, id]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="CModal scrollBar" style={{ maxWidth: 500 }}>
        <div className="flex justify-between items-center mb-7">
          <h5 className="font-semibold text-accent text-lg">Edit Record</h5>
          <i
            className="fas fa-times cursor-pointer text-xl"
            onClick={handleClose}
          ></i>
        </div>

        <form
          onSubmit={onSubmit}
          className="grid grid-cols-1 lg:grid-cols-2 gap-x-4"
        >
          <div className="form-control">
            <label>Full Name</label>
            <input
              type="text"
              onChange={(e) => setCompanyName(e.target.value)}
              required
              value={CompanyName}
            />
          </div>
          <div className="form-control">
            <label> Email Address</label>
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              required
              value={email}
            />
          </div>
          <div className="form-control">
            <label>Phone Number</label>
            <input
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              required
              value={phone}
            />
          </div>
          <div className="flex items-center mt-2">
            <button type="submit" className="button">
              {sending ? "Updating" : "Update"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
