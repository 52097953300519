import { Modal } from "@mui/material";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { url } from "../../Api";
import { PageContext } from "../../Context/PageContextProvider";

export const AddDeveloper = ({ open, handleClose }) => {
  const [sending, setSending] = useState(false);
  const { userInfo } = useContext(PageContext);

  const initialValues = {
    CompanyName: "",
    email: "",
    phone: "",
  };

  const onSubmit = (values, onSubmitProps) => {
    setSending(true);
    axios({
      url: `${url}/developers`,
      method: "post",
      data: values,
      headers: {
        Accept: "application/json",
        Authorization: `bearer ${userInfo?.token}`,
      },
    })
      .then((result) => {
        setSending(false);
        toast.success(result.data.message);
        handleClose();
        onSubmitProps.resetForm();
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setSending(false);
      });
     
  };

  const validationSchema = Yup.object({
    CompanyName: Yup.string().required("Field is Required!"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Field is Required!"),
    phone: Yup.string().required("Field is Required!"),
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="CModal scrollBar" style={{ maxWidth: 500 }}>
        <div className="flex justify-between items-center mb-7">
          <h5 className="font-semibold text-accent text-lg">
            Create Developer
          </h5>
          <i
            className="fas fa-times cursor-pointer text-xl"
            onClick={handleClose}
          ></i>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          <Form>
            <div className="form-control">
              <label>Full Name :</label>
              <Field
                type="text"
                name="CompanyName"
                placeholder="Enter realtor full name"
              />
              <ErrorMessage
                name="CompanyName"
                component="span"
                className="errorMsg"
              />
            </div>
            <div className="form-control">
              <label>Email :</label>
              <Field type="text" name="email" placeholder="Enter email" />
              <ErrorMessage
                name="email"
                component="span"
                className="errorMsg"
              />
            </div>
            <div className="form-control">
              <label>Phone :</label>
              <Field type="tel" name="phone" placeholder="Enter phone number" />
              <ErrorMessage
                name="phone"
                component="span"
                className="errorMsg"
              />
            </div>
            <button type="submit" className="button">
              {sending ? "Sending..." : "Create"}
            </button>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};
