import React, { useEffect, useState } from "react";
import { btnStyle } from "../../Pages/AllRealtors";
import { formatCurrency } from "../../utils/formatCurrency";
import { UpdateCommission } from "./UpdateCommission";
import { useTable } from "react-table";

export const PaymentHistory = ({ history }) => {
  const [search, setSearch] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [sales, setSales] = useState([]);
  const [getId, setGetId] = useState("");

  const openEdit = (id) => {
    setEditOpen(true);
    setGetId(id);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        Cell: ({ row }) => <span>{row.original.date.split("T")[0]}</span>,
        sortable: true,
      },
      {
        Header: "Amount",
        Cell: ({ row }) => <span>{formatCurrency(row.original.deposit)}</span>,
        sortable: true,
      },
      {
        Header: "Commission",
        Cell: ({ row }) => (row.original.commissionPaid ? "paid" : "unpaid"),
        sortable: true,
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <i
              onClick={() => openEdit(row.original._id)}
              class="ri-pencil-line text-xl cursor-pointer"
            ></i>
          </>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: history,
    });
  return (
    <div className="mt-5">
      <UpdateCommission
        historyId={getId}
        open={editOpen}
        handleClose={() => setEditOpen(false)}
      />

      <table {...getTableProps()} className="w-full table-auto text-left mt-4">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()} className="">
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  {...column.getHeaderProps()}
                  className="text-xs py-2 text-accent"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()} className="text-[#2E2E2E]">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      key={i}
                      {...cell.getCellProps()}
                      className="py-2 text-sm px-2"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
     
    </div>
  );
};
