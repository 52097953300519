import { Tooltip } from "@mui/material";
import React from "react";

export const WhatsAppGroup = () => {
  return (
    <a
      href="https://chat.whatsapp.com/IM491dYwN7uB0YflWzcdcP"
      target="blank"
      rel="noopener noreferrer"
    >
      <Tooltip title="Join WhatsApp Group">
        <i className="ri-whatsapp-line text-2xl z-50 cursor-pointer rounded-full text-white fa-arrow-up bg-green-500 h-12 w-12  flex items-center justify-center fixed bottom-5 right-7"></i>
      </Tooltip>
    </a>
  );
};
