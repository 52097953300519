import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../Layout/DashboardLayout";
import PageToper from "../Components/PageToper";
import { useTable } from "react-table";
import { Tooltip } from "@mui/material";
import { AddDeveloper } from "../Components/developers/AddDeveloper";
import { useFetchDevelopers } from "../hooks/useFetchDevelopers";
import { PageContext } from "../Context/PageContextProvider";
import { toast } from "react-toastify";
import axios from "axios";
import { url } from "../Api";
import DeleteConfirmationModal from "../Components/developers/ConfirmationModal";
import { EditDeveloper } from "../Components/developers/EditDeveloper";

const Developers = () => {
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDeveloper, setSelectedDeveloper] = useState(null);
  const { userInfo } = useContext(PageContext);
  const { developers, loading } = useFetchDevelopers(userInfo);
  const [openEditDev, setOpenEditDev] = useState(false);
  const [getId, setGetId] = useState("");
  const [search, setSearch] = useState("");
  const [filteredDevelopers, setFilteredDevelopers] = useState([]);

  const handleDelete = (id) => {
    setSelectedDeveloper(id);
    setDeleteModal(true);
  };

  const openEdit = (id) => {
    setOpenEditDev(true);
    setGetId(id);
  };

  useEffect(() => {
    setFilteredDevelopers(developers);
  }, [developers]);
  
  useEffect(() => {
    const result = developers.filter((item) => {
      return item.CompanyName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setFilteredDevelopers(result);
  }, [search]);

  const confirmDelete = () => {
    axios({
      url: `${url}/developers/${selectedDeveloper}`,
      method: "delete",
      headers: {
        Accept: "application/json",
        Authorization: `bearer ${userInfo?.token}`,
      },
    })
      .then((result) => {
        console.log(result);
        toast.success("Developer deleted successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to delete developer");
      })
      .finally(() => {
        setDeleteModal(false);
        setSelectedDeveloper(null);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "CompanyName",
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phone",
      },

      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <Tooltip
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "white",
                  },
                },
              }}
              title={
                <div className="bg-white divide-y divide-gray-100 rounded-lg shadow">
                  <ul className="py-2 text-sm text-gray-700">
                    <li>
                      <span
                        onClick={() => openEdit(row.original._id)}
                        className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        Edit
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => handleDelete(row.original._id)}
                        className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        Delete
                      </span>
                    </li>
                  </ul>
                </div>
              }
            >
              <i className="ri-more-2-fill text-xl cursor-pointer"></i>
            </Tooltip>
          </>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: filteredDevelopers,
    });

  return (
    <DashboardLayout>
      <AddDeveloper open={addModal} handleClose={() => setAddModal(false)} />
      <EditDeveloper
        open={openEditDev}
        handleClose={() => setOpenEditDev(false)}
        id={getId}
      />

      <DeleteConfirmationModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleConfirm={confirmDelete}
      />
      <div className="Container">
        <PageToper title="Developers" desc="All developers" adminAccount />
        <div className="flex justify-between">
          <div>
            <input
             value={search}
             onChange={(e) => setSearch(e.target.value)}
              placeholder="Search table.."
              className="border border-slate-500 py-2 pl-2 pr-5 font-medium rounded text-sm focus:outline-primary"
            />
          </div>
          <button className="button" onClick={() => setAddModal(true)}>
            Add +
          </button>
        </div>

        {!loading && developers.length === 0 && (
          <div>
            <h5 className="pt-4 font-medium text-lg"> No data yet</h5>
          </div>
        )}

        {loading && (
          <div>
            <h5 className="pt-4 font-medium text-lg">Loading....</h5>
          </div>
        )}

        {developers.length > 0 && (
          <div className="w-full overflow-x-auto">
            <table
              {...getTableProps()}
              className="w-full table-auto text-left mt-4"
            >
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr
                    key={i}
                    {...headerGroup.getHeaderGroupProps()}
                    className=""
                  >
                    {headerGroup.headers.map((column, i) => (
                      <th
                        key={i}
                        {...column.getHeaderProps()}
                        className="text-xs p-2 text-accent border"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()} className="text-[#2E2E2E]">
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <td
                            key={i}
                            {...cell.getCellProps()}
                            className="p-2 text-sm border"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default Developers;
